import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import './footer.component.scss';

export const Footer = () => {
    const { isCorporation } = useSelector(s => s.navbar);
    return (
        <footer>
            <div className="container">
                <div className="main-footer">
                    <div className="footer-col footer-col--brand">
                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M22.1404 0.20427L26.6331 4.70592H26.6328C26.9034 4.99872 26.9034 5.43875 26.6125 5.68983L17.9798 14.3791C17.7092 14.6514 17.2729 14.6514 17.0023 14.3791L8.36956 5.68983C8.09897 5.41747 8.09897 4.97828 8.36956 4.70592L12.8623 0.20427C13.1329 -0.0680901 13.5692 -0.0680901 13.8398 0.20427L16.8773 3.28193C17.2308 3.63771 17.7719 3.63771 18.1254 3.28193L21.1629 0.20427C21.4335 -0.0680901 21.8698 -0.0680901 22.1404 0.20427ZM0.20427 12.8596L4.70592 8.36686V8.36718C4.99872 8.0966 5.43875 8.0966 5.68983 8.38749L14.3791 17.0202C14.6514 17.2908 14.6514 17.7271 14.3791 17.9977L5.68983 26.6304C5.41747 26.901 4.97828 26.901 4.70592 26.6304L0.204271 22.1377C-0.0680897 21.8671 -0.0680897 21.4308 0.204271 21.1602L3.28193 18.1227C3.63771 17.7692 3.63771 17.2281 3.28193 16.8746L0.20427 13.8371C-0.0680901 13.5665 -0.0680901 13.1302 0.20427 12.8596ZM8.36686 30.2941L12.8596 34.7957C13.1302 35.0681 13.5665 35.0681 13.8371 34.7957L16.8746 31.7181C17.2281 31.3623 17.7692 31.3623 18.1227 31.7181L21.1602 34.7957C21.4308 35.0681 21.8671 35.0681 22.1377 34.7957L26.6304 30.2941C26.901 30.0217 26.901 29.5825 26.6304 29.3102L17.9977 20.6209C17.7271 20.3486 17.2908 20.3486 17.0202 20.6209L8.3875 29.3102C8.0966 29.5613 8.0966 30.0013 8.36718 30.2941H8.36686ZM34.7957 22.1404L30.2941 26.6331V26.6328C30.0013 26.9034 29.5613 26.9034 29.3102 26.6125L20.6209 17.9798C20.3486 17.7092 20.3486 17.2729 20.6209 17.0023L29.3102 8.36956C29.5825 8.09897 30.0217 8.09897 30.2941 8.36956L34.7957 12.8623C35.0681 13.1329 35.0681 13.5692 34.7957 13.8398L31.7181 16.8773C31.3623 17.2308 31.3623 17.7719 31.7181 18.1254L34.7957 21.1629C35.0681 21.4335 35.0681 21.8698 34.7957 22.1404Z" fill="#66AC46"/>
                        </svg>
                        <h3 className="footer__heading">豊泉家PCR検査センター</h3>
                        <p>〒561-0881 大阪府豊中市中桜塚3-2-1</p>
                    </div>
                    <div className="footer-col footer-col--info">
                        <p>
                            <span className="info-icon">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0V1.52102C1.17033 1.52279 2.25837 1.74759 3.19816 2.11877C3.3742 2.18833 3.54492 2.26296 3.7103 2.34247C4.08648 2.16167 4.49145 2.00569 4.92074 1.87864C5.69079 1.65161 6.53858 1.52102 7.43414 1.52102C8.60982 1.52102 9.70264 1.74625 10.6461 2.11877C10.8221 2.18833 10.993 2.26296 11.1581 2.34247C11.5344 2.16167 11.9394 2.00569 12.3687 1.87864C13.1348 1.65295 13.9779 1.52233 14.8684 1.52102V0H0Z" fill="white"/>
                                    <path d="M9.31006 5.76791C9.31029 6.1522 9.42601 6.50497 9.6254 6.80086C9.82476 7.09608 10.108 7.33113 10.439 7.47084C10.6599 7.56435 10.9018 7.61589 11.1583 7.61612C11.5428 7.61566 11.8958 7.50017 12.1913 7.30069C12.4866 7.10119 12.7216 6.8182 12.8615 6.487C12.9548 6.26618 13.0065 6.02428 13.0066 5.76794C13.0066 5.58467 12.9684 5.39031 12.8879 5.18683C12.8076 4.98334 12.6847 4.77097 12.5201 4.55994C12.2065 4.15655 11.7406 3.75914 11.1582 3.4248C10.7117 3.6816 10.333 3.97438 10.0399 4.27982C9.72768 4.60391 9.51354 4.94071 9.40348 5.25414C9.34026 5.43378 9.31006 5.60485 9.31006 5.76791Z" fill="white"/>
                                    <path d="M12.9673 3.6155C13.3549 4.01936 13.6494 4.46251 13.8168 4.93545C13.912 5.20535 13.9647 5.48546 13.9647 5.76805C13.9651 6.34739 13.7877 6.88986 13.4851 7.33725C13.1827 7.7851 12.7551 8.14028 12.2507 8.35379C11.9147 8.49596 11.5444 8.57457 11.1581 8.57457C10.5786 8.57457 10.0358 8.39755 9.58846 8.09475C9.14087 7.79218 8.78565 7.36481 8.5723 6.86053C8.43 6.52442 8.35136 6.15411 8.35148 5.76805C8.35148 5.34398 8.46999 4.92703 8.67335 4.5376C8.87704 4.14731 9.16564 3.781 9.52106 3.44513C9.70587 3.27094 9.90912 3.10504 10.1287 2.94796C9.98198 2.89421 9.83135 2.8438 9.67662 2.798C8.99378 2.59676 8.23616 2.47949 7.43411 2.47949C6.45036 2.47903 5.53328 2.65629 4.73924 2.94773C5.02935 3.15479 5.29094 3.37868 5.51948 3.6155C5.9069 4.01936 6.20146 4.46251 6.36884 4.93545C6.46415 5.20535 6.51691 5.48546 6.51691 5.76805C6.51703 6.34739 6.33989 6.88986 6.03721 7.33725C5.73464 7.7851 5.30714 8.14028 4.8029 8.35379C4.46679 8.49596 4.09647 8.57457 3.7103 8.57457C3.13064 8.57457 2.58803 8.39755 2.14064 8.09475C1.69293 7.79218 1.33775 7.36481 1.12436 6.86053C0.982069 6.52442 0.903545 6.15411 0.903661 5.76805C0.903661 5.34398 1.02206 4.92703 1.22531 4.5376C1.42911 4.14731 1.71771 3.781 2.07324 3.44513C2.25793 3.27094 2.46118 3.10504 2.68078 2.94796C2.53393 2.89421 2.38353 2.8438 2.22872 2.798C1.54977 2.59809 0.797027 2.4808 0 2.47949V9.75568H14.8684V2.47949C13.8899 2.4808 12.9776 2.65765 12.1872 2.94773C12.4773 3.15479 12.7388 3.37871 12.9673 3.6155Z" fill="white"/>
                                    <path d="M1.86182 5.76791C1.86205 6.1522 1.97777 6.50497 2.17716 6.80086C2.37652 7.09608 2.65974 7.33113 2.99086 7.47084C3.21167 7.56435 3.45358 7.61589 3.71014 7.61612C4.09446 7.61566 4.44752 7.50017 4.74309 7.30069C5.03842 7.10119 5.27336 6.8182 5.41327 6.487C5.50669 6.26618 5.55832 6.02428 5.55844 5.76794C5.55844 5.58467 5.52002 5.39031 5.4397 5.18683C5.35929 4.98334 5.23645 4.77097 5.07197 4.55994C4.75828 4.15655 4.29225 3.75914 3.70991 3.4248C3.26339 3.6816 2.88477 3.97438 2.59176 4.27982C2.27955 4.60391 2.06541 4.94071 1.95535 5.25414C1.89205 5.43378 1.86182 5.60485 1.86182 5.76791Z" fill="white"/>
                                </svg>
                            </span>
                            <a href="tel:0120-294-998">0120-294-998</a>
                        </p>
                        <p>
                            <span className="info-icon">
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M4.0279 5.22483L5.26584 3.92989C5.49092 3.70469 5.60346 3.19797 5.26584 2.86016L2.90249 0.213978C2.90249 0.213978 2.33979 -0.180134 1.83336 0.101374C1.3832 0.382883 -0.41745 1.79043 0.0889817 4.88702C0.651684 8.32143 6.67259 14.402 10.1051 14.9087C13.1999 15.4154 14.6067 13.6701 14.888 13.1634C15.1694 12.6567 14.8318 12.0936 14.8318 12.0936L12.1308 9.72897C11.6806 9.27856 11.2868 9.50376 11.0617 9.72897L9.82373 10.9676C8.86713 11.6432 3.35265 6.12566 4.0279 5.22483Z" fill="white"/>
                                </svg>
                            </span>
                            <a href="tel:06-6152-8252">06-6152-8252</a>
                        </p>
                        <p>
                            <span className="info-icon">
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5456 12H5.45422V12.6615H11.5456V12Z" fill="white"/>
                                    <path d="M11.5456 14H5.45422V14.6615H11.5456V14Z" fill="white"/>
                                    <path d="M16.5488 5.33778C16.2713 5.07603 15.8837 4.91306 15.4596 4.91306H13.8208V2.55187L11.1092 0H10.9429H3.17884V4.91306H1.54011C1.11563 4.91306 0.728078 5.07606 0.450655 5.33778C0.172817 5.59902 -0.000398883 5.96369 -0.000121738 6.36263V11.9034C-0.000398883 12.3026 0.172505 12.6673 0.450655 12.9288C0.72839 13.19 1.11608 13.3533 1.54011 13.3528H3.17884V16.6917H13.8208V13.3528H15.4596C15.8834 13.3533 16.2709 13.19 16.5484 12.9288C16.8267 12.6681 17.0001 12.3029 16.9999 11.9034V6.36266C17.0001 5.96343 16.8267 5.59902 16.5488 5.33778ZM10.8759 0.849189L12.8514 2.70878H10.8759V0.849189ZM13.0176 15.936H3.98204V12.9752H3.98163V9.88933H13.0173V13.3528H13.0176V15.936H13.0176ZM11.2648 7.50757C11.2648 7.20712 11.5238 6.96382 11.8425 6.96382C12.1618 6.96382 12.4203 7.20712 12.4203 7.50757C12.4203 7.80776 12.1618 8.05106 11.8425 8.05106C11.5238 8.05106 11.2648 7.80776 11.2648 7.50757ZM13.0176 4.91306H3.98204V0.755983H10.0727V3.46427H13.0176V4.91306H13.0176ZM14.3015 8.05106C13.9826 8.05106 13.7241 7.80776 13.7241 7.50757C13.7241 7.20712 13.9826 6.96382 14.3015 6.96382C14.6206 6.96382 14.8791 7.20712 14.8791 7.50757C14.8791 7.80776 14.6206 8.05106 14.3015 8.05106Z" fill="white"/>
                                </svg>
                            </span>
                            <a>06-6846-3206</a>
                        </p>
                        <p>
                            <span className="info-icon">
                                <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.130753 0.616699C0.037358 0.78481 0 0.952921 0 1.15839V8.03227C0 8.1817 0.0186791 8.31245 0.0747162 8.42453L4.48296 4.01628L0.130753 0.616699Z" fill="white"/>
                                    <path d="M14.7375 0.616699L10.3853 4.01628L14.7935 8.42453C14.8309 8.29377 14.8682 8.16302 14.8682 8.03227V1.15839C14.8682 0.952921 14.8122 0.78481 14.7375 0.616699Z" fill="white"/>
                                    <path d="M6.72445 4.539C7.15407 4.87522 7.73312 4.87522 8.14406 4.539L13.9159 0.0186791C13.8412 7.09764e-08 13.7851 0 13.7104 0H1.15811C1.08339 0 1.00867 7.09764e-08 0.952637 0.0186791L5.51032 3.60505L6.72445 4.539Z" fill="white"/>
                                    <path d="M8.74144 5.30477C8.34918 5.60363 7.8822 5.75306 7.43391 5.75306C6.96693 5.75306 6.49996 5.60363 6.12638 5.30477L5.26714 4.63232L0.78418 9.11529C0.914933 9.15264 1.04569 9.19 1.17644 9.19H13.7101C13.8595 9.19 13.9902 9.17132 14.1023 9.11529L9.61935 4.63232L8.74144 5.30477Z" fill="white"/>
                                </svg>
                            </span>
                            <a href="mailto:pcr-center@e-housenka.com" target="_blank">pcr-center@e-housenka.com</a>
                        </p>
                        <p>
                            <span className="info-icon">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 0C10.866 0 14 3.35789 14 7.5C14 11.6421 10.866 15 7 15C3.13401 15 0 11.6421 0 7.5C0 3.35789 3.13401 0 7 0ZM7 3.17308C6.70261 3.17308 6.46154 3.43137 6.46154 3.75V6.92308H4.03846C3.74107 6.92308 3.5 7.18137 3.5 7.5C3.5 7.81863 3.74107 8.07692 4.03846 8.07692H7C7.29739 8.07692 7.53846 7.81863 7.53846 7.5V3.75C7.53846 3.43137 7.29739 3.17308 7 3.17308Z" fill="white"/>
                                </svg>
                            </span>
                            月〜土曜日 9:00〜18:00
                        </p>
                    </div>
                    <div className="footer-col footer-col--links">
                        <div className="link-divider"></div>
                        <Link to="https://sf-housenka.org/company/" target="_blank" className="footer-link">法人概要</Link>
                        <Link to={`${isCorporation ? '/corporations' : ''}/privacy-policy`} className="footer-link">プライバシーポリシー</Link>
                        <Link to={`${isCorporation ? '/corporations' : ''}/terms-and-conditions`} className="footer-link">利用規約</Link>
                        <Link to={`${isCorporation ? '/corporations' : ''}/asct`} className="footer-link">特定商取引法に基づく表記</Link>
                    </div>
                </div>

                <div className="copyright">
                    <p>© 2024, 豊泉家PCR検査センター</p>
                </div>
            </div>
        </footer>
    )
}
