import React from 'react';
import { MenuLayout } from '../../components/menu-layout.component';
import { Footer } from '../../components/footer.component';

export default function TermsAndConditions() {
    return (
        <MenuLayout disableSecureLayout={true} isAbs={true} className="homepage transparent">
            <section className="docs">
                <div className="container">
                    <h1>豊泉家PCR検査センター</h1>
                    <h2 className="t-heading1 docs__heading t-center">利用規約</h2>
                    <div className="docs__block">
                        <p>新型コロナウイルス郵送<strong>PCR</strong>検査サービス利用規約</p>
                        <p>豊泉家PCR検査センターの新型コロナウイルス郵送PCR検査サービス（以下、「検査サービス」）の利用をされる方（以下、「被検者」）は、以下の本規約の定めに従うことを承諾したものとみなします。本規約の中の検査サービスは、豊泉家PCR検査センター（以下、「当センター」）が運営管理を行います。</p>
                        <p>第１条（被検者の事前同意）</p>
                        <ol>
                            <li>本検査サービスは、スクリーニングを目的としております。現時点で判明している情報に基づいてリアルタイムＰＣＲ法により新型コロナウイルスに特徴的なＲＮＡ配列が検出されるかどうかを検査するものです。</li>
                            <li>本検査サービスは、検査の精度の基準を満たしていますが、偽陽性、偽陰性などを示す場合が稀にあり、検査結果の正確性・安全性を100％保証するものではありません。</li>
                            <li>本検査サービスの検査結果は確定診断ではありませんので、当センターは被検者の疾病に対する関する一切の責任は負いません。また、当センターは本検査サービスの結果に起因する損害に対して一切の責任を負いません。</li>
                            <li>次の症状がある方は、当センターの検査サービスを受けずにただちに指定医療機関と都道府県の相談窓口へお問い合わせ頂き受診の相談をして下さい。
                        <ul>
                                    <li>重篤な症状（呼吸が苦しい、息切れ、胸の痛みなど）がある方</li>
                                    <li>ご高齢（65歳以上）または基礎疾患（糖尿病、高血圧、がん、心疾患、呼吸器疾患、肝疾患、腎疾患、透析を行っているなど）がある方で新型コロナウイルス感染症を疑う症状（発熱、咳、倦怠感、息苦しさ、嗅覚異常など）がある方</li>
                                </ul>
                            </li>
                            <li>本検査サービスは、医療機関以外での検体採取による判定検査です。被検者は、自己の責任に於いて自己採取を行ってください。医療法により他人の採取を行うことはできません。</li>
                            <li>検体採取される30分前は飲食を控えてください。検体採取前に飲食された場合、検査精度を保証することはできません。</li>
                            <li>検査完了後の採り直しのための検査キット送付は行っておりません。</li>
                            <li>検体採取キットはご注文日より、３ヶ月以内にご返送ください。３ヶ月を経過したものに関してはサービスの保証は致しかねます。</li>
                            <li>天候不良による出荷元の出荷配送時期の遅延、配送時また配送後の天候不良や天変地異、或いは配送業者による配送遅延が発生した場合には責任を負いません。</li>
                            <li>検体検査は、当センターが指定する医療機関で行います。</li>
                            <li>被検者の検査結果は、検体が検査場に到着後72時間以内に当センターから配信されます。状況によって所要日数より遅れる場合もあります。</li>
                            <li>検査結果は検査時の状態を示すものであり、結果に関わらず引き続き感染予防対策を行う必要があります。</li>
                            <li>検査結果によっては、当センターが指定する医療機関が、感染症及び政府当局の指示又は要請に基づき必要に応じて届出及び報告をする場合があります。その場合は、感染症の予防及び感染症の患者に対する医療に関する法律第12条第1項及び第14条第2項や政令の定めるところに基づく届出を行います。</li>
                            <li>検査結果については、研究目的および検査サービスの向上のため収集・公表する場合があります。収集した情報は分析、統計を主としたものであり、お客様の個人を特定するものではありません。</li>
                            <li>個人情報を含まない検査結果を、行政当局の求めるところに従ってご利用されている医療機関もしくは当センターが集計結果の形で提供する場合があります。また、研究目的および検査サービスの向上のためとして、当センター及び共同研究者が利用することがあります。</li>
                            <li>本検査サービスもしくは当センターが提供する業務に関する連絡、後日研究へのご協力依頼を行うために当センターから連絡させていただく場合があります。</li>
                            <li>本検査サービスならびに検査キットは、日本国内での利用に限ります。</li>
                            <li>当センターは、常に個人情報の取り扱いに十分留意し、法令を遵守するものとします。</li>
                            <li>団体での申込みの場合、当センターが必要と認めた時は、個人情報をその個人の所属する団体に報告ならびに共有する場合があります。</li>
                        </ol>
                        <p>第２条（検査サービス申込み）</p>
                        <p>被検者は、検査サービスを受けるため、本規約を承諾の上、当センターが定める方法により検査サービス申込みを行ってください。被検者は、本規約のすべてを遵守することを前提とします。</p>
                        <p>第３条（検査キット登録）</p>
                        <ol>
                            <li>被検者は、検査キット登録のために提供した情報の内容について一切の責任を負います。</li>
                            <li>当センターは、検査キット登録のために被検者にアカウントを発行します。</li>
                            <li>被検者は、検査サービスにおけるアカウントを１アカウントのみ設定することができます。また、被検者は、自身のアカウントを、他者に利用させることはできません。</li>
                            <li>被検者は、被検者が設定したアカウントを通じ、検査サービスを利用することができます。</li>
                            <li>被検者は検査キット登録した情報を、検査サービスを利用するために必要な範囲内で、被検者自らが変更、追加、削除できるものとし、常に被検者が責任をもって正確な状態に保つものとします。</li>
                            <li>被検者は、自らの意思により検査キット登録を削除することができます。登録を削除した場合には、被検者は、データにアクセスすることができなくなります。ただし、検査キット登録されたデータのうち、医師が当該データを保管する義務または必要性のある限りにおいて継続保管・利用することができるものとします。</li>
                            <li>被検者は、自らの責任と費用において、検査キット登録に必要な環境（ハードウェア、ソフトウェア、インターネット接続回線、セキュリティの確保等）を整備するものとします。当センターは、被検者のデータの情報セキュリティを確保するために、検査キット登録に関するインフラストラクチャ、ネットワーク、アプリケーション及びデータ等を対象とした情報セキュリティ対策を行うこととします。</li>
                            <li>被検者は、検査キット登録で使用するEメールアドレス、パスワード等が第三者に知られないように管理する責任を負い、Eメールアドレス、パスワード等が被検者の故意または過失により第三者に知られることによって自身に損害が生じた場合、その損害を自らの責任で負担するものとし、当社はこれに一切の責任を負わないものとします。</li>
                        </ol>
                        <p>第４条（被検者の責任）</p>
                        <p>被検者は、本規約に定める事項を遵守すると共に、下記の行為は行わないものとします。</p>
                        <ol>
                            <li>被検者が、本検査サービスの検査を受ける際、所定の申し込み記入欄に虚偽内容を記載する行為</li>
                            <li>検査サービスにより利用できる情報の改竄</li>
                            <li>有害なマルウェア等を送信し、書き込む行為</li>
                            <li>当センター又は、それから受託している第三者の著作権、知的財産商標権等を侵害、又は侵害する恐れのある行為</li>
                            <li>検査キットを第三者に転売する行為</li>
                            <li>検査サービスの運営を妨げる行為、又はその恐れのある行為</li>
                            <li>他者になりすまして検査サービスを利用する行為</li>
                            <li>本人の同意を得ることなく又は詐欺的な手段により他者の個人情報を収集する行為</li>
                            <li>その他法令に違反する行為又は当センターが不適当と判断する行為</li>
                        </ol>
                        <p>第５条（被検者情報の取り扱い等）</p>
                        <p>当センターが保有する被検者情報については、個人情報に適用される法令を当センターは、遵守します。</p>
                        <ol>
                            <li>被検者は、当センターが検査サービスの業務の一部を委託した業者又は所属する団体等に委託業務の処理に必要な範囲で被検者が第１条によって、検査サービスに申込んだ情報を使用されることに同意するものとします。</li>
                            <li>当センターは、本条１項及び２項の情報を個人情報として秘密に管理するものとします。但し、下記の場合は、第三者への提供ができるものとします。</li>
                        </ol>
                        <p>（１）被検者の事前の了承が得られた時</p>
                        <p>（２）被検者から開示を求められたとき</p>
                        <p>（３）法令等により開示を求められたとき</p>
                        <p>（４）被検者の所属する団体等</p>
                        <p>（５）本規約に基づく場合</p>
                        <ol start="3">
                            <li>当センターは、第１条によって被検者が登録した情報は、当センターに営業上もしくは検査サービスに付随する次回のご利用もしくは新たな検査に関するご案内等に利用することが出来るものとします。</li>
                        </ol>
                        <p>第６条（本規約の変更）</p>
                        <p>当センターは、被検者の承諾を得ることなく、被検者に通知することにより本規約を変更できるものとします。改訂された本規約は通知した時点で効力が発生します。尚、当センターの WEB サイト上でも公表いたします。</p>
                        <p>第７条（サービスの一時中断・停止）</p>
                        <ol>
                            <li>当センターは、サービス提供に品質維持向上のため、検査サービス環境の保守点検、設備更新、運営上の必要性が認められたとき、その目的達成のため検査サービスの一時中断、停止・変更することがあります。又、天変地異や不可抗力による災害のため検査サービスの一時中断・停止・変更することがあります。これによる被検者の損害については当センターの責めは負わないものとします。</li>
                            <li>当センターは、営業上その他の理由により予告なく検査サービスの廃止を行うことがあります。</li>
                        </ol>
                        <p>第８条（免責責任）</p>
                        <p>被検者が検査サービスを通じ知り得る情報等について、その有効性等について当センターはいかなる保障も行いません。又、これらに起因して生じる被検者の損害に対して一切の責任は負いません。</p>
                        <p>第９条（瑕疵担保責任）</p>
                        <p>被検者は、万一検体採取キットに品質上の問題が発生した場合は、検体採取キット受領 14 日間以内に当センターに通知するものとします。その期間を超えた場合は、当センターは保証しかねます。品質上の問題による返品は、当センターにて負担いたしますので着払いにて当センター宛にご返品ください。</p>
                        <p>第１０条（クーリングオフ）</p>
                        <p>本検査サービスのクーリング・オフは、検査キット到着後、７日間以内に申し入れがあった場合にのみ適用といたします。尚、被検者により検査キットの返却後、使用の痕跡が認められた時は、この条文は規定外とします。クーリング･オフによる検体採取キットのご返送に係わる費用は被検者のご負担とします。</p>
                        <p>第１１条（検査サービスの仕様変更）</p>
                        <p>検体採取キットや検査方法又は検査サービスの仕様、サービス内容等々は予告なく変更する場合があります。</p>
                        <p>第１２条（合意管轄）</p>
                        <p>検査サービスの利用に関して被検者と当センターで生じた紛争については、大阪地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
                        <p>第１４条（お問い合わせ窓口）</p>
                        <p>本規約に関するお問い合わせは，下記の窓口までお願いいたします。</p>
                        <p>住所：〒561-0881<br /> 大阪府豊中市中桜塚3-2-1　豊泉家新予防センター</p>
                        <p>法人名：一般財団法人 SF豊泉家</p>
                        <p>担当部署：豊泉家PCR検査センター</p>
                        <p>Eメールアドレス：pcr-center@e-housenka.com</p>
                    </div>
                </div>
            </section>
            <Footer />
        </MenuLayout>
    );
}
